import {
  showSnackbarFailMessage,
  showSnackbarSuccessMessage,
  showSnackbarWarningMessage,
} from '@/utls/snackbar';
import DefaultDialogCUDetails from './DefaultDialogCUDetails';
import { languagesKeys, languagesKeysValue } from '@/config/config';

const initData = () => ({
  formLanguage: { ...languagesKeys() }, // { ar:{ }, en:{ } }
  languagesKeysValue: languagesKeysValue(), // { key:ar, value'عربي' },
});

export default ({ isWithLanguage = false, languageKey = '' } = {}) => ({
  props: ['isView', 'isEdit', 'itemId', 'item'],
  mixins: [DefaultDialogCUDetails()],
  data() {
    return {
      ...initData(),
    };
  },
  async created() {
    if (this.isEdit || this.isView) {
      const responseData = await this.getById(this.itemId);
      if (responseData == null) return; // there is no data - error
      for (const k in this.$data) {
        const value = responseData[k];
        if (value == undefined) continue;
        this.$data[k] = value;
      }

      if (isWithLanguage && languageKey) {
        for (const k in this.$data.formLanguage) {
          const languageDetail = responseData[languageKey].find(
            (x) => x.language == k,
          );
          if (languageDetail == undefined) continue;
          this.$data.formLanguage[k] = languageDetail;
        }
      }
    }
  },
  methods: {
    async getById() {
      this.$store.commit('loading/SET_DIALOG_LOADING', true);

      const { getUrl, getHeader } = this.crudOptions;
      const config = { params: { language: 'all' }, headers: { ...getHeader } };

      return this.$http
        .get(getUrl, config)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          showSnackbarFailMessage('حدث خطأ اثناء عملية جلب البيانات');
          this.closeDialog(false, true);
          return null;
        })
        .finally(() => this.$store.commit('loading/SET_DIALOG_LOADING', false));
    },
    clearData(data = {}) {
      const _initData = { ...initData(), ...data };
      for (const k in this.$data) this.$data[k] = _initData[k];
    },
  },
  computed: {
    inputLanguages() {
      if (!isWithLanguage || !languageKey) return;

      const languages = this.languagesKeysValue.reduce((p, v) => {
        const { key, value } = v;
        const name = this.formLanguage[key].name;
        if (name) return [...p, { language: key, name }];
        return p;
      }, []);
      return languages;
    },
  },
});
