<template>
  <UploadManager
    v-model="imageModel"
    :selectedLajnguage="selectedLajnguage"
    :buttonTitle="buttonTitle"
    :buttonIcon="buttonIcon"
    :multiple="multiple"
    uploadNotes="upload image from upload image manager"
    uploadType="image"
    accept="image/png, image/jpeg, image/bmp"
    browseFileIcon="mdi-camera"
    title="الصور"
    gridTitle="اختر الصور"
    uploadTitle="تحميل صورة"
    selectFileToUploadMsg="اختر الصورة المراد تحميلها"
    successUploadMsg="تم تحميل الصورة"
    fileNameLabel="اسم الصورة"
    :onSelectCloseDialog="onSelectCloseDialog"
    :btnProps="btnProps"
  >
    <!-- ----------------------------------------------------------------- -->
    <!-- filesGridPreview -->
    <!-- ----------------------------------------------------------------- -->
    <template
      #filesGridPreview="{
        files,
        selectFile,
        getSelectedFileClass,
        isSelected,
        remove,
        isLoading: fileLoading,
        editFile,
      }"
    >
      <v-col
        v-for="(
          { src: fileSrc, alt, id: fileId, extension, creator }, index
        ) in files"
        :key="index"
        class="d-flex child-flex"
        cols="6"
        xs="6"
        sm="4"
        md="3"
        lg="2"
        xl="2"
      >
        <v-card
          @click="selectFile(fileSrc)"
          outlined
          elevation="0"
          class="d-flex flex-column"
          :class="`${getSelectedFileClass(fileSrc)}`"
        >
          <v-card-text class="image-conainer pa-0">
            <v-card-actions class="centerFloat">
              <v-btn
                small
                class="btnHide error darken-2"
                @click.stop="remove(fileId)"
                :disabled="fileLoading"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                small
                class="btnHide"
                @click.stop="
                  editFile(fileId, alt, getSrc(fileSrc, 'lg'), extension)
                "
                :disabled="fileLoading"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-card-actions>

            <v-img
              :src="getSrc(fileSrc, 'md')"
              :lazy-src="`https://picsum.photos/10/6?image=${index * 5}`"
              contain
              aspect-ratio="1"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <!-- <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular> -->
                </v-row>
              </template>
            </v-img>
          </v-card-text>
          <div class="d-flex justify-space-between flex-column flex-grow-1">
            <v-card-title class="subtitle-1 pb-1"> {{ alt }} </v-card-title>
            <v-card-subtitle class="subtitle-1 pb-1 pt-0 ma-0">
              <v-chip x-small>
                <v-icon x-small class="ml-1">mdi-account</v-icon>
                {{ getUserName(creator) }}
              </v-chip>
            </v-card-subtitle>
          </div>
        </v-card>
      </v-col>
    </template>

    <!-- ----------------------------------------------------------------- -->
    <!-- uploadfilePreview -->
    <!-- ----------------------------------------------------------------- -->
    <template
      #uploadfilePreview="{
        browseFile,
        file,
        fileSrc,
        changeUploadFile,
        uploadFile,
        fileId,
        getFileFromUrl,
        isLoadingFile,
        taggleEdit,
      }"
    >
      <v-fade-transition mode="out-in">
        <div class="file-container">
          <v-container
            v-if="isLoadingFile"
            class="d-flex justify-center align-center fill-height"
            fluid
          >
            <v-progress-circular
              :size="100"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-container v-else fluid>
            <v-btn
              :disabled="isEditImage"
              v-if="fileSrc"
              @click="
                () => {
                  changeUploadFile(null), toggleEditImage(false);
                }
              "
              fab
              dark
              class="ma-2"
              absolute
              style="top: 10px; left: 10px"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn
              v-if="fileSrc"
              @click="
                () => {
                  toggleEditImage();
                  getFileFromUrl();
                  taggleEdit();
                }
              "
              fab
              dark
              class="ma-2"
              :class="isEditImage ? 'red' : ''"
              absolute
              style="top: 70px; left: 10px"
            >
              <v-icon v-if="isEditImage">mdi-cancel</v-icon>
              <v-icon v-else>mdi-crop</v-icon>
            </v-btn>
            <v-btn
              v-if="fileSrc"
              :disabled="isEditImage"
              @click="uploadFile"
              fab
              dark
              class="ma-2"
              absolute
              style="top: 130px; left: 10px"
            >
              <v-icon v-if="fileId">mdi-content-save</v-icon>
              <v-icon v-if="!fileId">mdi-upload</v-icon>
            </v-btn>

            <v-row v-if="fileSrc && !isEditImage" class="fill-height pa-0 ma-0">
              <v-col class="fill-height d-flex justify-center">
                <img
                  :src="fileSrc"
                  style="margin: auto; height: 100%; max-width: 100%"
                />
              </v-col>
            </v-row>

            <image-cropper
              v-else-if="fileSrc && isEditImage"
              :file="file"
              :fileSrc="fileSrc"
              ref="ImageCropper"
              @post="
                (file) => {
                  changeUploadFile(file);
                  toggleEditImage();
                  taggleEdit();
                }
              "
            />

            <v-row
              v-else
              @click="browseFile"
              class="grey fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-icon color="white">mdi-image</v-icon>
            </v-row>
          </v-container>
        </div>
      </v-fade-transition>
    </template>
    <!-- ----------------------------------------------------------------- -->
    <!-- selectedFiles -->
    <!-- ----------------------------------------------------------------- -->
    <template #selectedFiles="{ files, selectFile }">
      <v-subheader>الصور المختارة</v-subheader>
      <v-sheet class="mx-auto" height="120px">
        <v-slide-group multiple show-arrows :center-active="true">
          <v-slide-item
            v-for="(fileSrc, index) in files"
            :key="index"
            v-slot="{ active, toggle }"
          >
            <div class="selected-file-container">
              <div
                class="close d-flex justify-center"
                @click="selectFile(fileSrc)"
              >
                <v-icon color="white">mdi-close</v-icon>
              </div>
              <img
                :src="getSrc(fileSrc, 'sm')"
                class="ma-2"
                :input-value="active"
              />
            </div>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </template>
    <!-- ----------------------------------------------------------------- -->
  </UploadManager>
</template>

<script>
import { getImageURL } from '@/helper/cmsPaths';
import ImageCropper from './components/ImageCropper.vue';
import UploadManager from './UploadManager.vue';

export default {
  props: ['value', 'selectedLajnguage', 'text', 'icon', 'multiple', 'onSelectCloseDialog' , 'btnProps'],
  components: { UploadManager, ImageCropper },
  data() {
    return {
      isEditImage: false,
    };
  },
  computed: {
    imageModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    buttonTitle() {
      return this.text || 'اضافة/الغاء صور';
    },
    buttonIcon() {
      return this.icon || 'mdi-image';
    },
  },
  methods: {
    getSrc(src, size) {
      return getImageURL(src, size);
    },
    toggleEditImage(value) {
      this.isEditImage = value ?? !this.isEditImage;
    },
    getUserName(creator) {
      return creator?.userName || '';
    },
  },
};
</script>

<style lang="scss" scoped>
.image-conainer {
  display: flex;
  justify-content: center;
  align-items: center;

  .centerFloat {
    position: absolute;
    z-index: 1;
  }

  .btnHide {
    opacity: 0;
  }

  &:hover {
    .btnHide {
      opacity: 1;
    }
  }
}

.file-container {
  background-color: rgba(0, 0, 0, 0.068);
  padding: 1px;
  height: 62vh;

  .container {
    height: 100%;
  }
}

.selected-file-container {
  position: relative;
  margin: 8px;
  height: 95px;
  // width: 95px;

  img {
    // width: 100%;
    height: 100%;
    margin: 0 !important;
    object-fit: cover;
  }

  .close {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    /* Black see-through */
    width: 100%;
    height: 100%;
    transition: 0.5s ease;
    opacity: 0;
  }

  &:hover .close {
    opacity: 1;
    cursor: pointer;
  }
}
</style>
