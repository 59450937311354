<template>
  <DefaultDialog
    v-if="isCanRead"
    width="65%"
    height="85vh"
    buttonText="القوائم"
    title="القوائم المرتبطة بالنوع"
    :openToEdit="openToEdit"
    editTitle="تعديل القوائم"
    :openToView="openToView"
    viewTitle="عرض البيانات"
    @onCloseDialog="onCloseDialog"
    :customSaveButton="true"
    :customAddButton="true"
  >
    <template #saveButton="{ on, attrs, click }">
      <slot name="saveButton" :attrs="attrs" :on="on" :click="click"> </slot>
    </template>

    <template #addButton="{ on, attrs, click }">
      <slot name="addButton" :attrs="attrs" :on="on" :click="click"> </slot>
    </template>

    <NavTypeNavigationsTree
      slot-scope="{
        submitWithClose,
        submitWithNew,
        submitWithoutClose,
        closeDialog,
        isEdit,
        isView,
        isNew,
      }"
      :submitWithClose="submitWithClose"
      :submitWithNew="submitWithNew"
      :submitWithoutClose="submitWithoutClose"
      :setItemID="setItemID"
      :closeDialog="closeDialog"
      :isEdit="isEdit"
      :isView="isView"
      :isNew="isNew"
      :afterSubmit="afterSubmit"
      :itemId="itemId"
      :navTypeId="navTypeId"
    />
  </DefaultDialog>
</template>

<script>
import CRUDMixin from '@/mixins/DefaultDialogCRUD';
import NavTypeNavigationsTree from '../crud/NavTypeNavigationsTree.vue';
import { appPermissions } from '@/libs/auth/permissions/appPermissions';
import PermissionMixin from '@/mixins/PermissionMixin';
export default {
  props: ['navTypeId'],
  components: { NavTypeNavigationsTree },
  mixins: [CRUDMixin(), PermissionMixin(appPermissions.navigations)],
};
</script>

<style lang="scss" scoped></style>
