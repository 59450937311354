<template>
  <v-card elevation="2" color="background" class="d-flex flex-column flex-1">
    <v-card-title>
      انواع القوائم
      <v-spacer></v-spacer>

      <DefaultDialog
        buttonText="اضافة"
        title="اضافة نوع"
        :openToEdit="openToEdit"
        editTitle="تعديل نوع"
        :openToView="openToView"
        viewTitle="عرض البيانات"
        @onCloseDialog="onCloseDialog"
        :hideDetailOnLoading="false"
        :showAddButton="isCanCreate"
      >
        <NavTypeDetail
          slot-scope="{
            submitWithClose,
            submitWithNew,
            submitWithoutClose,
            closeDialog,
            isEdit,
            isView,
            isNew,
          }"
          :submitWithClose="submitWithClose"
          :submitWithNew="submitWithNew"
          :submitWithoutClose="submitWithoutClose"
          :setItemID="setItemID"
          :closeDialog="closeDialog"
          :afterSubmit="getNavTypes"
          :itemId="itemId"
          :isEdit="isEdit"
          :isView="isView"
          :isNew="isNew"
        />
      </DefaultDialog>
    </v-card-title>

    <v-card-text class="d-flex flex-column flex-grow-1">
      <!-- Filters -->
      <v-row class="flex-grow-0">
        <v-col cols="12">
          <languages-filter @change="changeLanguage" />
        </v-col>
      </v-row>

      <!-- NavTypeCard -->
      <v-row v-if="$store.state.loading.show">
        <v-flex xs6 md4 lg4 xl2>
          <v-skeleton-loader type="article" />
        </v-flex>
      </v-row>
      <v-row class="flex-grow-0" dense v-else>
        <v-flex xs6 md4 lg4 xl2 v-for="(item, index) in navTypes" :key="index">
          <NavTypeCard
            :item="item"
            @viewRow="viewRow(item)"
            @editRow="editRow(item)"
            @deleteRow="deleteRow(item)"
          />
        </v-flex>
      </v-row>

      <v-spacer />
      <!-- pagination -->
      <v-row class="justify-center flex-grow-0 my-1">
        <v-col cols="12" class="d-flex justify-space-between align-center">
          <v-pagination v-model="options.pageIndex" :length="numberOfPages" />
          <v-select
            v-model="options.pageSize"
            :items="[5, 10, 15, 20]"
            label="عدد الصفوف"
            solo
            dense
            hide-details
            item-color="white"
            class="flex-grow-0 d-flex align-center"
            style="width: 100px"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import qs from 'qs';
import LanguagesFilter from '@/components/LanguagesFilter.vue';
import { selectedLanguage } from '@/config/config';
import DefaultDialog from '@/components/DefaultDialog.vue';
import NavTypeDetail from './crud/NavTypeDetail.vue';
import { mapActions } from 'vuex';
import { showSnackbarFailMessage } from '@/utls/snackbar';
import NavTypeCard from './components/NavTypeCard.vue';
import PermissionMixin from '@/mixins/PermissionMixin';
import { appPermissions } from '@/libs/auth/permissions/appPermissions';

export default {
  components: {
    LanguagesFilter,
    NavTypeDetail,
    DefaultDialog,
    NavTypeCard,
  },
  mixins: [PermissionMixin(appPermissions.navTypes)],
  data() {
    return {
      sel: null,
      navTypes: [],
      total: 0,
      headers: [
        { text: '', value: 'controls', sortable: false },
        { text: 'slug', value: 'slug' },
        { text: 'الأسم', value: 'language.name' },
      ],
      options: {
        pageIndex: 1,
        pageSize: 15,
        language: selectedLanguage,
        title: '',
      },
      // for view edit and detail
      // ------------------------
      openToEdit: false,
      openToView: false,
      itemId: null,
      // ------------------------
    };
  },
  computed: {
    skipPerPage() {
      return this.options.pageSize * (this.options.pageIndex - 1);
    },
    numberOfPages() {
      const pages = Math.ceil(this.total / this.options.pageSize) || 1;
      return pages;
    },
  },
  created() {
    this.getNavTypes();
  },
  methods: {
    ...mapActions('confirm', ['openConfirm']),
    deleteRow(item) {
      this.openConfirm({
        message: 'هل انت متأكد من حذف النوع ؟',
        confirm: () => {
          return this.$http
            .delete('app/nav-type/' + item.id, {})
            .then(() => {
              this.getNavTypes();
              return true;
            })
            .catch((error) => {
              showSnackbarFailMessage('حدث خطأ اثناء عملية الحذف');
              return false;
            });
        },
      });
    },
    onCloseDialog() {
      this.openToEdit = false;
      this.openToView = false;
    },
    editRow(item) {
      this.openToEdit = true;
      this.itemId = item.id;
    },
    viewRow(item) {
      this.openToView = true;
      this.itemId = item.id;
    },
    setItemID(id) {
      if (id) return this.editRow({ id });
      this.itemId = null;
      this.onCloseDialog();
    },
    changeLanguage(language) {
      this.options.language = language;
    },
    getNavTypes() {
      this.$store.commit('loading/SET_SHOW', true);

      const requestConfig = {
        params: {
          skipCount: this.skipPerPage,
          maxResultCount: this.options.pageSize,
          language: this.options.language,
          title: this.options.title,
        },
        paramsSerializer: function (params) {
          const queryString = qs.stringify(params, { arrayFormat: 'brackets' });
          return queryString;
        },
      };

      this.$http
        .get('app/nav-type', requestConfig)
        .then((response) => {
          const { items, totalCount } = response.data;

          const resultData = items.reduce((p, c) => {
            const language = c.navTypeLanguages[0];
            const data = [...p, { ...c, language }];
            return data;
          }, []);

          this.navTypes = resultData;
          this.total = totalCount;
        })
        .catch((error) => {
          showSnackbarFailMessage('حدث خطأ اثناء عملية جلب البيانات');
        })
        .finally(() => this.$store.commit('loading/SET_SHOW', false));
    },
    searchUser() {},
  },
  watch: {
    options: {
      handler() {
        this.getNavTypes();
      },
      deep: true,
    },
  },
};
</script>
