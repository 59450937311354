<template>
  <v-text-field
    v-model="modelValue"
    :error-messages="slugErrors"
    label="slug"
    required
    outlined
    hide-details="auto"
    dense
    @input="$v.modelValue.$touch()"
    @blur="$v.modelValue.$touch()"
    append-icon="mdi-recycle"
    @click:append="appendIconCallbackSlug"
  />
</template>

<script>
import { makeid } from '@/utls/jsCommon';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

const mustChangeValue = (param) => (value) => {
  if (!param) return true; // pass : no need to validate
  if (param == value) return false; // not pass
  return true; // pass
};

export default {
  props: ['value', 'foundedSlug'],
  methods: {
    appendIconCallbackSlug() {
      this.modelValue = makeid(15);
    },
    pureValue(value) {
      const withoutSpace = value.trim().replace(/ /g, '-');
      return withoutSpace;
    },
  },
  mixins: [validationMixin],
  validations() {
    return {
      modelValue: {
        mustChangeValue: mustChangeValue(this.foundedSlug),
        required,
      },
    };
  },
  computed: {
    modelValue: {
      get() {
        return this.pureValue(this.value);
      },
      set(value) {
        this.$emit('input', this.pureValue(value));
      },
    },
    slugErrors() {
      const errors = [];
      if (!this.$v.modelValue.$dirty) return errors;
      !this.$v.modelValue.required && errors.push('الحقل مطلوب');
      !this.$v.modelValue.mustChangeValue &&
        errors.push('يرجى تغيير القيمة موجودة');
      return errors;
    },
  },
};
</script>

<style lang="scss" scoped></style>
