<template>
  <div class="d-flex flex-column">
    <v-fab-transition>
      <div>
        <NavigationDialog
          :typeId="navTypeId"
          :afterSubmit="afterSubmit"
          v-model="navigationDialog"
        >
          <template #addButton="{ on, attrs, click }">
            <template v-if="navigationsPermissions.isCanCreate">
              <v-btn
                v-show="canChange && !isChangedSorted"
                class="mx-5 fab-btn"
                color="primary"
                elevation="1"
                v-on="on"
                @click="addNew && click"
                :attrs="attrs"
                :loading="loading"
                fab
                bottom
                right
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          </template>
        </NavigationDialog>

        <template v-if="navigationsPermissions.isCanUpdate">
          <v-btn
            class="mx-5 fab-btn"
            color="primary"
            elevation="1"
            v-if="isChangedSorted && canChange"
            @click="sortDataByTree"
            :loading="loading"
            fab
            bottom
            right
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>

        <v-btn
          class="mx-5 fab-btn"
          color="primary"
          v-if="!isEdit && isNew && canChange"
          elevation="1"
          @click="saveNew"
          :loading="loading"
          bottom
          left
        >
          <v-icon v-html="'حفظ'" />
        </v-btn>
      </div>
    </v-fab-transition>

    <v-row class="py-4 px-2 flex-1">
      <!-- Tree -->
      <v-col class="fill-height">
        <v-card elevation="0" class="pa-5" style="overflow-y: auto">
          <div v-if="isEmpty">لا توجد قوائم</div>
          <Tree
            v-else
            :value="copiedTreeData"
            rtl
            ref="tree"
            :eachDraggable="eachDraggable"
            :eachDroppable="eachDroppable"
            :ondragstart="ondragstart"
            :ondragend="ondragend"
          >
            <div slot-scope="{ node, index, path, tree }">
              <v-card
                class="pa-2 clickable"
                :style="getSelectedStyle(node.id)"
                @click="navigationCardClicked($event, tree, node, path)"
                elevation="0"
              >
                <v-row class="align-center">
                  <v-col cols="1" class="py-0">
                    <v-icon
                      v-if="node.children && node.children.length"
                      class="mx-2"
                      small
                      color="primary"
                      @click.stop="tree.toggleFold(node, path)"
                      elevation="0"
                      v-html="node.$folded ? 'mdi-plus' : 'mdi-minus'"
                    />
                  </v-col>

                  <v-col cols="6" class="pa-0 d-flex gap-2 align-center">
                    <b class="py-0 ml-3" v-html="getTitle(node, path)"></b>
                    <div>{{ getSubtitle(node, path) }}</div>
                  </v-col>

                  <v-col cols="5" class="py-0">
                    <v-card-actions class="justify-end align-center">
                      <template v-if="navigationsPermissions.isCanUpdate">
                        <v-text-field
                          v-if="canChange"
                          v-model="node.order"
                          dense
                          hide-details
                          class="ml-2"
                          type="number"
                          height="20px"
                          style="width: 50px; flex: 0 0 50px"
                          v-on:keyup.enter="
                            sortDataByString(node.id, node.parentId, node.order)
                          "
                        />
                      </template>
                      <template v-if="navigationsPermissions.isCanUpdate">
                        <v-icon
                          v-if="canChange"
                          class="mr-1"
                          @click.stop="editRow(node)"
                          color="#ffa000"
                          >mdi-pencil-outline</v-icon
                        >
                      </template>
                      <template v-if="navigationsPermissions.isCanDelete">
                        <v-icon
                          v-if="canChange"
                          color="pink"
                          class="mr-1"
                          @click.stop="deleteRow(node)"
                          >mdi-delete</v-icon
                        >
                      </template>
                      <NavigationDialog
                        :typeId="navTypeId"
                        :afterSubmit="afterSubmit"
                        :parentId="itemId"
                        v-model="NewChilednavigationDialog"
                      >
                        <template #addButton="{ on, attrs, click }">
                          <template v-if="navigationsPermissions.isCanCreate">
                            <v-icon
                              v-show="canChange && !isChangedSorted"
                              color="primary"
                              class="mr-1"
                              v-on="on"
                              @click="addNew && click && setItemId(node.id)"
                              :attrs="attrs"
                              :loading="loading"
                              >mdi-plus</v-icon
                            >
                          </template>
                        </template>
                      </NavigationDialog>
                    </v-card-actions>
                  </v-col>
                </v-row>
                <!-- <b @click="tree.toggleFold(node, path)">
                {{ node.$folded ? '+' : '-' }}
              </b>
              <br />
              {{ node.name }}
              <br />
              <b>{{ index }}</b>
              <br />
              {{ path.join('|') }} -->
              </v-card>
            </div>
          </Tree>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import NavigationDetail from '@/views/navigations/curd/NavigationDetail.vue';
import NavigationShortDetail from '@/views/navTypes/crud/NavigationShortDetail.vue';

import 'he-tree-vue/dist/he-tree-vue.css'; // base style
import {
  Tree, // Base tree
  Fold,
  Check,
  Draggable, // plugins
  foldAll,
  unfoldAll,
  cloneTreeData,
  walkTreeData,
  getPureTreeData, // utils
} from 'he-tree-vue';
import {
  showSnackbarFailMessage,
  showSnackbarSuccessMessage,
} from '@/utls/snackbar';
import { mapActions } from 'vuex';
import PermissionMixin from '@/mixins/PermissionMixin';
import { appPermissions } from '@/libs/auth/permissions/appPermissions';
import NavigationDialog from '../dialog/NavigationDialog.vue';

export default {
  props: {
    openToView: {
      type: Boolean,
      default: false,
    },
    openToNew: {
      type: Boolean,
      default: false,
    },
    navTypeId: {
      type: Number,
      required: true,
    },
  },
  mixins: [
    PermissionMixin(appPermissions.navigations, {
      name: 'navigationsPermissions',
    }),
  ],
  components: {
    NavigationDetail,
    NavigationShortDetail,
    Tree: Tree.mixPlugins([Draggable, Fold]),
    NavigationDialog,
  },
  data: () => ({
    submit: 0,
    itemId: null,
    isView: false,
    isEdit: false,
    isNew: false,
    treeData: [
      // { name: 'node 1', order: 1 },
      // { name: 'node 2', order: 2, children: [{ name: 'node 2-1', order: 21 }] },
    ],
    treeModel: null,
    modifyData: [],
    isEmpty: false,
    navigationDialog: {},
    NewChilednavigationDialog : {},
    loading: false,
  }),
  computed: {
    canChange() {
      return !this.openToView && !this.openToNew;
    },
    copiedTreeData() {
      var cloneData = cloneTreeData(this.treeData);
      return cloneData;
    },
    isChangedSorted() {
      if (!this.modifyData || this.modifyData.length == 0) return false;
      if (this.changedData.length == 0) return false;

      // change isSorteChanged in store
      this.$store.commit('sorting/UPDATE_SORTING', true);
      return true;
    },
    changedData() {
      const changedData = this.modifyData.filter(
        (x) => x.modifyOrder != x.order || x.modifyParentId != x.parentId,
      );
      return changedData || [];
    },
  },
  async created() {
    this.loadTreeData();
  },
  mounted() {
    this.treeModel = this.$refs.tree;
  },
  destroyed() {
    // console.log({
    //   treeModel: this.treeModel,
    //   originData: this.treeData,
    //   modifyData: this.treeModel.getPureTreeData(),
    //   store: this.treeModel.treesStore.store,
    // });
  },
  methods: {
    ...mapActions('confirm', ['openConfirm']),
    getSelectedStyle(id) {
      const bk = { backgroundColor: '#b4e5ff82' };
      const br = this.isEdit ? { borderRight: '5px solid red' } : {};
      if (this.itemId == id) return { ...bk, ...br };
      return {};
    },
    async loadTreeData() {
      if (this.openToNew) {
        this.treeData = [];
        this.isEmpty = true;
        return;
      }

      const { items, totalCount } = await this.fetchData(this.navTypeId);
      this.treeData = this.prepareTreeData(items);
      this.isEmpty = this.treeData.length == 0;
    },
    initChangedData() {
      const attr = 'data-tree-node-path';
      const theNode = [...document.querySelectorAll(`[${attr}]`)];
      theNode.forEach((x) => {
        const path = x.getAttribute(attr).split(',');
        const newOrder = this.getOrderFromPath(path);
        const newParent = this.treeModel.getNodeParentByPath(path);
        const node = this.treeModel.getNodeByPath(path);
        this.addModifyElement(node, newOrder, newParent);
      });
    },
    getOrderFromPath(path, join) {
      return path.map((x) => ++x).join(join || '');
    },
    getTitle(node, path) {
      // if (!node.item || !node.item) return;
      // return node.item.url;
      return this.getOrderFromPath(path, '-') /*+ ' <br/> ' + node.order*/;
    },
    getSubtitle(node, path) {
      if (!node.item || !node.item.navigationLanguages) return;
      return node.item.navigationLanguages.map((x) => x.name).join(' - ');
    },
    navigationCardClicked(e, tree, node, path) {
      this.viewRow(node);
    },
    prepareTreeData(items) {
      let compare = (a, b) => {
        try {
          const orderitem1 = parseInt(a.order);
          const orderitem2 = parseInt(b.order);
          if (orderitem1 < orderitem2) return -1;
          if (orderitem1 > orderitem2) return 1;
        } catch (error) {}
        return 0;
      };
      const sortedArray = items.sort(compare);
      return sortedArray.map((x) => ({
        ...x,
        modifyOrder: null,
        modifyParentId: null,
      }));
    },
    setLoading(status = false) {
      this.loading = status;
    },
    sortDataByTree() {
      this.initChangedData();
      const postData = this.changedData.map((x) => ({
        id: x.id,
        parentId: x.modifyParentId,
        order: x.modifyOrder,
      }));
      this.saveSortData(postData);
    },
    sortDataByString(id, parentId, order) {
      this.openConfirm({
        message: 'هل انت متأكد من تغيير الترتيب ؟',
        confirm: () => {
          this.initChangedData();
          const postData = [
            {
              id: id,
              parentId: parentId,
              order: order,
            },
          ];
          this.saveSortData(postData);
          return true;
        },
      });
    },
    async saveSortData(postData) {
      this.setLoading(true);
      return this.$http
        .put('app/navigation/sort', postData)
        .then((response) => {
          this.modifyData = [];
          this.loadTreeData();

          if (response.status != 200) return false;
          showSnackbarSuccessMessage('تم الحفظ بنجاح');
          return true;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => {
          this.setLoading(false);
          this.$store.commit('sorting/UPDATE_SORTING', false);
        });
    },
    async fetchData(navTypeId) {
      if (!this.navigationsPermissions.isCanRead || !navTypeId)
        return { items: [], totalCount: 0 };

      this.setLoading(true);
      return this.$http
        .get('app/navigation/tree', {
          params: { navTypeId, language: 'all' },
        })
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          showSnackbarFailMessage('حدث خطأ اثناء عملية جلب البيانات');
          this.closeDialog(false, true);
          return null;
        })
        .finally(() => this.setLoading(false));
    },
    viewRow(item) {
      if (this.itemId == item.id) {
        // TODO : need refactor
        this.isView = false;
        this.isEdit = false;
        this.itemId = null;
        this.isNew = false;
        return;
      }
      this.itemId = item.id;
      this.isView = true;
      this.isEdit = false;
      this.isNew = false;
    },
    editRow(item) {
      // TODO : need refactor
      this.isEdit = true;
      this.isView = false;
      this.itemId = item.id;
      this.isNew = false;
      this.navigationDialog.editRow({ id: item.id });
    },
    saveEdit() {
      this.submit++;
    },
    cancelEdit() {
      // TODO : need refactor
      this.isEdit = false;
      this.isView = false;
      this.itemId = null;
      this.isNew = false;
    },
    addNew() {
      // TODO : need refactor
      this.isEdit = false;
      this.isView = false;
      this.itemId = null;
      this.isNew = true;
    },
    saveNew() {
      this.submit++;
    },
    afterSubmit() {
      // TODO : need refactor
      this.cancelEdit();
      this.modifyData = [];
      this.loadTreeData();
    },
    setItemId(id) {
      this.itemId = id;
    },
    deleteRow(item) {
      this.openConfirm({
        message: 'هل انت متأكد من حذف القائمة ؟',
        confirm: () => {
          return this.$http
            .delete('app/navigation/' + item.id, {})
            .then(() => {
              this.modifyData = [];
              this.loadTreeData();
              return true;
            })
            .catch((error) => {
              showSnackbarFailMessage('حدث خطأ اثناء عملية الحذف');
              return false;
            });
        },
      });
    },
    eachDraggable(currentPath, tree, store) {
      // Disable Draggable
      if (!this.navigationsPermissions.isCanUpdate) return false;

      //   console.log('Start Draggable', {
      //     currentPath: currentPath.join('|'),
      //     element: tree.getNodeByPath(currentPath)?.name,
      //     parent: tree.getNodeParentByPath(currentPath)?.name,
      //   });
    },
    eachDroppable(currentPath, tree, store) {
      //   console.log('End', {
      //     currentPath: currentPath.join('|'),
      //     element: tree.getNodeByPath(currentPath)?.name,
      //     parent: tree.getNodeParentByPath(currentPath)?.name,
      //   });
    },
    ondragstart(tree, store) {},
    ondragend(tree, store) {
      this.treeModel = tree;
      // console.log({ dragedElement: this.getDataOfLastElementDrop() });
      const { dragNode, targetPath } = this.getDataOfLastElementDrop();
      const newOrder = this.getOrderFromPath(targetPath);
      const newParent = this.treeModel.getNodeParentByPath(targetPath);
      this.addModifyElement(dragNode, newOrder, newParent);
    },
    getDataOfLastElementDrop() {
      if (!this.treeModel) return null;

      const { treesStore } = this.treeModel;
      if (!treesStore) return null;

      const { store } = treesStore;
      if (!store) return null;

      const {
        dragNode,
        pathChanged,
        pathChangePrevented,
        startPath,
        targetPath,
      } = store;

      return {
        dragNode,
        pathChanged,
        pathChangePrevented,
        startPath,
        targetPath,
      };
    },
    addModifyElement(element, newOrder, newParent) {
      var alreadyModify = this.modifyData.find((x) => x.id == element.id);

      const parentId = (newParent && newParent.id) || null;
      if (alreadyModify) {
        alreadyModify.modifyOrder = newOrder;
        alreadyModify.modifyParentId = parentId;
        return;
      }

      this.modifyData.push({
        ...element,
        modifyOrder: newOrder,
        modifyParentId: parentId,
      });

      // console.log({ element, newOrder, newParent });
      // console.log({ modifyData: this.modifyData });
    },
  },
};
</script>

<style lang="scss">
.tree-node {
  padding: 0 !important;
}
</style>

<style lang="scss">
:not(.theme--dark).clickable:hover {
  background: #dfdfdf;
}

.dragging :not(.theme--dark).clickable {
  background: #fdfae1;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.fab-btn {
  bottom: 0;
  position: absolute;
  margin: 0 0 16px 16px;
  z-index: 999;
}
</style>
