<template>
  <v-row class="fill-height">
    <v-col style="position: relative" class="fill-height">
      <!-- aspectRatios -->
      <!-- ---------------------------------- -->
      <v-row
        class="mx-2 align-center"
        style="position: absolute; z-index: 100; height: 100%"
      >
        <v-col cols="12" class="ma-0">
          <v-row class="flex-column">
            <v-col cols="1" v-for="(item, index) in aspectRatios" :key="index">
              <v-btn
                fab
                dark
                :color="
                  aspectRatio == item.value ? 'primary lighten-2' : 'grey'
                "
                @click="changeAspectRatio(item.value)"
              >
                <v-icon v-if="item.label.startsWith('mdi')">
                  {{ item.label }}
                </v-icon>
                <template v-else>
                  {{ item.label }}
                </template>
              </v-btn>
            </v-col>
            <v-col cols="1">
              <v-btn fab dark color="green darken-2" @click="postCroppedImage">
                <v-icon>mdi-content-save-check</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- cropper -->
      <!-- ---------------------------------- -->
      <v-row class="fill-height">
        <v-col cols="12" class="fill-height ma-0 py-0">
          <cropper
            class="cropper"
            :src="src"
            :stencil-props="{
              aspectRatio: aspectRatio,
            }"
            :canvas="{
              minHeight: 0,
              minWidth: 0,
              maxHeight: 2048,
              maxWidth: 2048,
              height: 1500,
              width: 1800,
            }"
            @change="change"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { createFileFromBlob, getSrcFromFile } from '@/helper/commonjs';

export default {
  props: ['file'],
  data() {
    return {
      // image: null,
      aspectRatio: 2.35 / 1,
      src: null,
      blob: null,
    };
  },
  components: {
    Cropper,
  },
  computed: {
    aspectRatios() {
      return [
        { label: 'mdi-all-inclusive', value: null },
        { label: '1:1', value: 1 / 1 },
        { label: '16:9', value: 16 / 9 },
        { label: '2.35:1', value: 2.35 / 1 },
      ];
    },
  },
  methods: {
    change({ coordinates, canvas }) {
      canvas.toBlob((blob) => (this.blob = blob));
    },
    changeAspectRatio(aspectRatio) {
      this.aspectRatio = aspectRatio;
    },
    readFileAndChangePreview(inputFile) {
      if (inputFile == null) {
        this.src = null;
        return;
      } else {
        getSrcFromFile(inputFile, (src, e) => {
          this.src = src;
        });
      }
    },
    postCroppedImage() {
      if (this.file == null) return;
      if (this.file != null) {
        const { name, type } = this.file;
        const file = createFileFromBlob(this.blob, name, type);
        this.$emit('post', file);
      }
    },
  },
  watch: {
    file: {
      immediate: true,
      handler(val) {
        this.readFileAndChangePreview(val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.cropper {
  height: 100%;
  width: 100%;
  background: grey;
  margin: auto;
}
</style>
<style lang="scss">
.vue-advanced-cropper__background,
.vue-advanced-cropper__foreground {
  background: #0000004a !important;
}
</style>
