<template>
  <DefaultDialog
    width="50%"
    height="40%"
    buttonText="القائمة"
    title="اضافة قائمة"
    :openToEdit="openToEdit"
    editTitle="تعديل قائمة"
    :openToView="openToView"
    viewTitle="عرض البيانات"
    @onCloseDialog="onCloseDialog"
    :customAddButton="true"
  >
    <template #addButton="{ on, attrs, click }">
      <slot name="addButton" :attrs="attrs" :on="on" :click="click"> </slot>
    </template>

    <NavigationShortDetail
      slot-scope="{
        submitWithClose,
        submitWithNew,
        submitWithoutClose,
        closeDialog,
        isEdit,
        isView,
        isNew,
      }"
      :submitWithClose="submitWithClose"
      :submitWithNew="submitWithNew"
      :submitWithoutClose="submitWithoutClose"
      :setItemID="setItemID"
      :closeDialog="closeDialog"
      :isEdit="isEdit"
      :isView="isView"
      :isNew="isNew"
      :afterSubmit="afterSubmit"
      :itemId="itemId"
      :typeId="typeId"
      :parentId="parentId"
    />
  </DefaultDialog>
</template>

<script>
import CRUDMixin from '@/mixins/DefaultDialogCRUD';
import NavigationShortDetail from '../crud/NavigationShortDetail.vue';
export default {
  props: ['typeId', 'parentId'],
  components: { NavigationShortDetail },
  mixins: [CRUDMixin()],
};
</script>

<style lang="scss" scoped></style>
