<template>
  <BaseDialog
    wdith="65%"
    title="التعديلات"
    :isLoading="isLoading"
    @onShow="showHandler"
  >
    <template #button="{ toggle }">
      <slot name="button" :toggle="toggle" />
    </template>
    <template #body="{}">
      <v-container fluid class="ma-0 pa-0 pt-4">
        <v-row>
          <v-col cols="12" class="px-0">
            <v-timeline align-top dense>
              <v-timeline-item
                v-for="(change, index) in changesWithColsName"
                :key="index"
                :color="getColor(change)"
              >
                <AuditLogCard
                  :change="change"
                  :color="getColor(change)"
                  :type="getAuditType(change)"
                  :label="getLabel(change)"
                  :renderSlot="renderSlot"
                  class="ml-15"
                >
                  <template #originalValue="{ item, value }">
                    <slot name="originalValue" :item="item" :value="value" />
                  </template>
                  <template #newValue="{ item, value }">
                    <slot name="newValue" :item="item" :value="value" />
                  </template>
                </AuditLogCard>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </BaseDialog>
</template>

<script>
import { languages } from '@/config/config';
import axiosIns from '@/libs/http/http';
import BaseDialog from '../BaseDialog.vue';
import AuditLogCard from './AuditLogCard.vue';
export default {
  components: { BaseDialog, AuditLogCard },
  props: ['colsName', 'url', 'params', 'renderSlot', 'entitiesLabel'],
  data() {
    return {
      isLoading: false,
      changes: [],
    };
  },
  computed: {
    changesWithColsName() {
      const colsName = this.colsName || {};

      let changes = this.changes.map((change) => {
        const { entityChange } = change;

        const { propertyChanges } = entityChange;
        if (!propertyChanges || propertyChanges.length == 0) return change;

        const newPropertyChanges = propertyChanges.map((prop) => {
          const { propertyName } = prop;
          const col = colsName[propertyName];
          return { ...prop, displayPropertyName: col || propertyName };
        });

        return {
          ...change,
          entityChange: {
            ...entityChange,
            propertyChanges: newPropertyChanges,
          },
        };
      });

      return changes;
    },
  },
  methods: {
    showHandler() {
      this.getChanges();
    },
    getChanges() {
      if (!this.url) return;

      // Items have already been requested
      if (this.isLoading) return;
      else this.isLoading = true;
      // Lazily load input items
      axiosIns
        .get(this.url, { params: this.params || {} })
        .then((response) => {
          if (typeof response.data == 'string') return (this.changes = []);
          this.changes = response.data;
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    getColor({ entityChange, isRecovery, isOnlySaved }) {
      const { changeType } = entityChange || {};
      if (isRecovery) return 'secondary lighten-1';
      if (changeType == 0) return 'green lighten-1';
      if (changeType == 1) {
        if (isOnlySaved) return 'primary lighten-2';
        return 'primary lighten-1';
      }
      if (changeType == 2) return 'red lighten-2';
    },
    getAuditType({ entityChange, isRecovery, isOnlySaved }) {
      const { changeType } = entityChange || {};
      if (isRecovery) return 'recovery';
      if (changeType == 0) return 'add';
      if (changeType == 1) {
        if (isOnlySaved) return 'save';
        return 'update';
      }
      if (changeType == 2) return 'delete';
      return '';
    },
    getLabel({ entityChange, changeCode, entityLabel }) {
      const { entityTypeFullName } = entityChange;

      const fullLabel = [];
      const label1 = this.generateLabelFromEntityType(entityTypeFullName);
      if (label1) fullLabel.push(label1);

      if (entityLabel) fullLabel.push(entityLabel);

      const label2 = this.generateLabelFromCode(entityTypeFullName, changeCode);
      if (label2) fullLabel.push(label2);

      return fullLabel.join('-');
    },
    generateLabelFromEntityType(entityTypeFullName) {
      if (!this.entitiesLabel) return '';
      const keys = Object.keys(this.entitiesLabel);
      const label = keys.reduce((p, v) => {
        if (!entityTypeFullName.includes(v)) return p;
        return this.entitiesLabel[v] || '';
      }, '');
      if (label) return label;
      return '';
    },
    generateLabelFromCode(entityTypeFullName, changeCode) {
      if (!changeCode) return '';
      if (entityTypeFullName.includes('Languages')) {
        const language = languages.find((x) => x[changeCode]);
        const label = language && language[changeCode];
        return label || '';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped></style>
