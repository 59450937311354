<template>
  <form class="fill-height">
    <v-container fluid class="fill-height">
      <v-row class="d-flex flex-row-reverse fill-height">
        <v-col cols="12" md="3" class="flex-grow-1 fill-height"
        :class="{'overflow-y-auto': $vuetify.breakpoint.name != 'sm'}"
        >
          <v-row :class="isNew ? 'mt-5' : ''">
            <template v-if="!isNew">
              <v-col cols="12" class="py-0 d-flex" v-if="isEdit">
                <NavTypeNavigationsBtn :navTypeId="itemId" />
              </v-col>

              <v-col class="py-0">
                <AuditLog
                  :url="`app/nav-type/${itemId}/changes`"
                  class="my-0"
                  :creationTime="creationTime"
                  creationUsername="admin"
                  :lastModificationTime="lastModificationTime"
                  lastModificationUsername="admin"
                  :colsName="{
                    Name: 'الاسم',
                    Slug: 'slug',
                    Icon: 'icon',
                    Url: 'الرابط',
                    Image: 'الصورة',
                    ParentId: 'الاب',
                  }"
                  :renderSlot="true"
                  :entitiesLabel="{
                    'NavTypes.NavType': 'النوع',
                    'NavTypesLanguages.NavTypeLanguage': 'لغة النوع',
                    'Navigations.Navigation': 'القائمة',
                    'NavigationsLanguages.NavigationLanguage': 'لغة القائمة',
                  }"
                >
                  <template #originalValue="{ item, value }">
                    <span>{{
                      getLogValue(item, value, { isOriginal: true })
                    }}</span>
                  </template>
                  <template #newValue="{ item, value }">
                    <span>{{
                      getLogValue(item, value, { isOriginal: false })
                    }}</span>
                  </template>
                </AuditLog>
              </v-col>
            </template>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h1>بيانات اساسية</h1>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <SlugBase v-model="slug" :foundedSlug="foundedSlug" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="9" class="flex-grow-1 overflow-y-auto fill-height">
          <v-row>
            <v-col cols="12">
              <!-- TABS HEADER -->
              <v-card elevation="0">
                <v-tabs
                  show-arrows
                  background-color="indigo"
                  dark
                  v-model="tab"
                  align-with-title
                  fixed-tabs
                  height="45px"
                  class="mb-4"
                >
                  <v-tabs-slider color="white darken-1"></v-tabs-slider>
                  <v-tab
                    v-for="lang in languagesKeysValue"
                    :key="lang.key"
                    class="white--text font-weight-black"
                    active-class="indigo lighten-2"
                  >
                    {{ lang.value }}
                  </v-tab>
                </v-tabs>

                <!-- TABS CONTENT -->
                <v-tabs-items v-model="tab">
                  <v-tab-item
                    v-for="lang in languagesKeysValue"
                    :key="lang.key"
                  >
                    <v-card flat>
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="formLanguage[`${lang.key}`][`name`]"
                              :counter="50"
                              :label="'الاسم بالـ ' + lang.value"
                              outlined
                              dense
                              hide-details="auto"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { showSnackbarFailMessage } from '@/utls/snackbar';
import NavigationsTree from '../components/NavigationsTree.vue';
import { makeid } from '@/utls/jsCommon';
import AuditLog from '@/components/auditLog/AuditLog.vue';
import SlugBase from '@/components/SlugBase.vue';
import NavTypeNavigationsBtn from '../components/NavTypeNavigationsBtn.vue';
import DefaultDialogCRUDDetails from '@/mixins/DefaultDialogCRUDDetails';

const initData = () => ({
  slug: '',
  tab: null,
  creationTime: '',
  lastModificationTime: '',
  foundedSlug: '',
});

export default {
  components: {
    NavigationsTree,
    AuditLog,
    SlugBase,
    NavTypeNavigationsBtn,
  },
  mixins: [
    validationMixin,
    DefaultDialogCRUDDetails({
      isWithLanguage: true,
      languageKey: 'navTypeLanguages',
    }),
  ],
  validations: {
    slug: { required },
  },
  data: () => ({ ...initData() }),
  async created() {
    if (this.isNew) this.slug = makeid(15);
  },
  computed: {
    crudOptions() {
      return {
        getUrl: 'app/nav-type/' + this.itemId,
        postUrl: 'app/nav-type',
        putUrl: 'app/nav-type/' + this.itemId,
      };
    },
  },
  methods: {
    getLogValue(item, value, { isOriginal }) {
      if (item.propertyName == 'Image') {
        const isThereValue = value.length > 2;
        return this.getLogFieldValue(isThereValue, isOriginal, 'صورة');
      }
      if (item.propertyName == 'ParentId') {
        return value;
      }
      return value;
    },
    getLogFieldValue(isThereValue, isOriginal, label) {
      if (isOriginal) return isThereValue ? label : '';
      return isThereValue ? 'تغيير ' + label : '';
    },
    onCreateUpdateError(error, fullResponoseError) {
      const { code, message } = error;
      if (code == 'Slug_Found') {
        this.foundedSlug = this.slug;
        return showSnackbarFailMessage(message);
      }
      showSnackbarFailMessage('حدث خطأ اثناء عملية الحفظ');
    },
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) return true;
      return false;
    },
    postedData() {
      const slug = this.slug;
      const languages = this.inputLanguages;
      const postData = { slug, navTypeLanguages: languages };
      return postData;
    },
    clearInput() {
      this.clearData(initData());
      this.slug = makeid(15);
    },
  },
};
</script>

<style lang="scss" scoped></style>
