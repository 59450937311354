export default (
  permissions,
  { name, dynamicKey, updateOnQueryChanged = false } = {},
) => {
  const permissionsName = name || 'permissions';
  const computedName = (name && name + 'Is') || 'is';
  const refreshPermissionName = [(name || '') + 'refreshPermission'];

  return {
    data() {
      return {
        [permissionsName]: {},
      };
    },
    computed: {
      [computedName + 'CanRead']() {
        const { isCanRead = false } = this[permissionsName];
        return isCanRead;
      },
      [computedName + 'CanCreate']() {
        const { isCanCreate = false } = this[permissionsName];
        return isCanCreate;
      },
      [computedName + 'CanUpdate']() {
        const { isCanUpdate = false } = this[permissionsName];
        return isCanUpdate;
      },
      [computedName + 'CanDelete']() {
        const { isCanDelete = false } = this[permissionsName];
        return isCanDelete;
      },
      [computedName + 'CanManagePermissions']() {
        const { isCanManagePermissions = false } = this[permissionsName];
        return isCanManagePermissions;
      },
    },
    methods: {
      [refreshPermissionName]() {
        if (!permissions) return;
        var keyVal = this[dynamicKey];
        this[permissionsName] = permissions(keyVal) || {};
      },
    },
    created() {
      this[refreshPermissionName]();
    },
    watch: {
      '$route.query'() {
        if (updateOnQueryChanged) this[refreshPermissionName]();
      },
    },
  };
};
