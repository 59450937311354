<template>
  <form>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h1>بيانات اساسية</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <Autocomplete
            url="app/navigation/by-filters"
            :params="{ navigationName: '', language: 'all' }"
            displayProp="navigationLanguages[0].name"
            :value="parent"
            label="القائمة الرئيسية"
            placeholder="ابحث عن القائمة الرئيسية"
            @onChange="(value) => (parent = value)"
            renderSlot
          >
            <template #render="{ item }">
              <v-list-item-content>
                <v-list-item-title v-html="item.link" color="blue" />
                <div class="d-inline-flex">
                  <v-list-item-subtitle
                    v-for="(lang, index) in item.navigationLanguages"
                    :key="index"
                    v-html="lang.name"
                  />
                </div>
              </v-list-item-content>
            </template>
          </Autocomplete>
        </v-col>

        <v-col cols="4">
          <Autocomplete
            url="app/nav-type"
            :params="{ language: 'all' }"
            displayProp="slug"
            label="نوع القائمة"
            placeholder="ابحث عن نوع القائمة"
            :value="navType"
            @onChange="(value) => (navType = value)"
            :error-messages="navTypeErrors"
            @input="$v.navType.$touch()"
            @blur="$v.navType.$touch()"
            renderSlot
          >
            <template #render="{ item }">
              <v-list-item-content>
                <v-list-item-title v-html="item.slug" color="blue" />
                <div class="d-inline-flex">
                  <v-list-item-subtitle
                    v-for="(lang, index) in item.navTypeLanguages"
                    :key="index"
                    v-html="lang.name"
                  />
                </div>
              </v-list-item-content>
            </template>
          </Autocomplete>
        </v-col>

        <v-col cols="4">
          <v-text-field
            v-model="icon"
            :error-messages="iconErrors"
            label="icon"
            required
            outlined
            @input="$v.icon.$touch()"
            @blur="$v.icon.$touch()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="url"
            :error-messages="urlErrors"
            label="الرابط"
            required
            outlined
            @input="$v.url.$touch()"
            @blur="$v.url.$touch()"
            class="link-input"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="target"
            :error-messages="targetErrors"
            label="target"
            required
            outlined
            @input="$v.target.$touch()"
            @blur="$v.target.$touch()"
            class="link-input"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h1>اللغة</h1>
        </v-col>
      </v-row>
    </v-container>

    <v-card elevation="1">
      <!-- TABS HEADER -->
      <v-toolbar flat>
        <v-tabs
          show-arrows
          background-color="indigo"
          dark
          v-model="tab"
          align-with-title
          fixed-tabs
        >
          <v-tabs-slider color="white darken-1"></v-tabs-slider>
          <v-tab
            v-for="lang in languagesKeysValue"
            :key="lang.key"
            class="white--text font-weight-black"
            active-class="indigo lighten-2"
          >
            {{ lang.value }}
          </v-tab>
        </v-tabs>
      </v-toolbar>

      <!-- TABS CONTENT -->
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="lang in languagesKeysValue" :key="lang.key">
          <v-card flat>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="formLanguage[`${lang.key}`][`name`]"
                    :counter="50"
                    :label="'الاسم بالـ ' + lang.value"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </form>
</template>

<script>
import Autocomplete from '@/components/Autocomplete.vue';

import { languagesKeys, languagesKeysValue } from '@/config/config';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import {
  showSnackbarFailMessage,
  showSnackbarSuccessMessage,
  showSnackbarWarningMessage,
} from '@/utls/snackbar';
import { getErrorDetails } from '@/helper/commonjs';

export default {
  props: [
    'submit',
    'afterSubmit',
    'closeDialog',
    'isNew',
    'isView',
    'isEdit',
    'itemId',
  ],
  components: {
    Autocomplete,
  },
  mixins: [validationMixin],
  validations: {
    navType: { required },
    url: { required },
    target: { required },
    icon: { required, maxLength: maxLength(50) },
  },
  data: () => ({
    // for languages tab
    tab: null,
    // for languages
    languagesKeysValue: languagesKeysValue(),
    formLanguage: { ...languagesKeys() }, // { ar:{ }, en:{ } }
    // for main info
    parentId: null,
    parent: null,
    url: '',
    icon: '',
    navType: null,
    navTypeId: null,
    target: '',
  }),
  async created() {
    if (this.isEdit || this.isView) {
      const responseData = await this.getById(this.itemId);
      if (responseData == null) return; // there is no data - error

      for (const k in this.$data) {
        const value = responseData[k];
        if (value == undefined) continue;
        this.$data[k] = value;
      }

      for (const k in this.$data.formLanguage) {
        const languageDetail = responseData.navigationLanguages.find(
          (x) => x.language == k,
        );
        if (languageDetail == undefined) continue;
        this.$data.formLanguage[k] = languageDetail;
      }
    }
  },
  computed: {
    // validation
    // ----------------
    navTypeErrors() {
      const errors = [];
      if (!this.$v.navType.$dirty) return errors;
      !this.$v.navType.required && errors.push('نوع القائمة مطلوب');
      return errors;
    },
    urlErrors() {
      const errors = [];
      if (!this.$v.url.$dirty) return errors;
      !this.$v.url.required && errors.push('الرابط مطلوب');
      return errors;
    },
    iconErrors() {
      const errors = [];
      if (!this.$v.icon.$dirty) return errors;
      !this.$v.icon.required && errors.push('الحقل مطلوب');
      return errors;
    },
    targetErrors() {
      const errors = [];
      if (!this.$v.target.$dirty) return errors;
      !this.$v.target.required && errors.push('الحقل مطلوب');
      return errors;
    },
    inputLanguages() {
      const languages = this.languagesKeysValue.reduce((p, v) => {
        const { key, value } = v;
        const name = this.formLanguage[key].name;
        if (name) return [...p, { language: key, name }];
        return p;
      }, []);
      return languages;
    },
  },
  methods: {
    doSave(postData) {
      this.$store.commit('loading/SET_DIALOG_LOADING', true);
      return this.$http
        .post('app/navigation', postData)
        .then((response) => {
          if (response.status != 200) return false;
          showSnackbarSuccessMessage('تم الحفظ بنجاح');
          return true;
        })
        .catch(this.errorSaveHabdler)
        .finally(() => this.$store.commit('loading/SET_DIALOG_LOADING', false));
    },
    doUpdate(postData) {
      this.$store.commit('loading/SET_DIALOG_LOADING', true);
      return this.$http
        .put('app/navigation/' + this.itemId, { ...postData })
        .then((response) => {
          if (response.status != 200) return false;
          showSnackbarSuccessMessage('تم الحفظ بنجاح');
          return true;
        })
        .catch(this.errorSaveHabdler)
        .finally(() => this.$store.commit('loading/SET_DIALOG_LOADING', false));
    },
    errorSaveHabdler(error) {
      const { message } = getErrorDetails(error)

      return showSnackbarFailMessage(message ?? 'حدث خطأ اثناء عملية الحفظ');
    },
    clearInput: function () {},
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) return true;
      return false;
    },
    async getById(id) {
      this.$store.commit('loading/SET_DIALOG_LOADING', true);
      return this.$http
        .get('app/navigation/' + id, { params: { language: 'all' } })
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          showSnackbarFailMessage('حدث خطأ اثناء عملية جلب البيانات');
          this.closeDialog(false, true);
          return null;
        })
        .finally(() => this.$store.commit('loading/SET_DIALOG_LOADING', false));
    },
  },
  watch: {
    submit() {
      if (this.isInvalid()) {
        showSnackbarWarningMessage('يجب عدم ترك الاعمدة المطلوبة فارغة');
        return;
      }

      const parentId = (this.parent && this.parent.id) || null;
      const url = this.url;
      const icon = this.icon;
      const navTypeId = this.navType.id;
      const target = this.target;

      const languages = this.inputLanguages;
      const postData = {
        parentId,
        url,
        icon,
        navTypeId,
        target,
        navigationLanguages: languages,
      };
      if (this.isNew) {
        this.doSave(postData).then((status) => {
          if (!status) {
            showSnackbarFailMessage('حدث خطأ اثناء عملية الحفظ');
            return;
          }
          this.clearInput();
          this.closeDialog(true);
          this.afterSubmit();
        });
      } else {
        
        if (this.itemId == parentId) {
          showSnackbarWarningMessage('قيمة القائمة الرئيسية غير مقبولة');
          return;
        }
        
        // TODO : AH Need Refactor
        this.doUpdate(postData).then((status) => {
          if (!status) {
            showSnackbarFailMessage('حدث خطأ اثناء عملية الحفظ');
            return;
          }
          this.clearInput();
          this.closeDialog(true);
          this.afterSubmit();
        });
      }
    },
  },
};
</script>

<style lang="scss">
.link-input {
  input {
    direction: ltr;
    text-align: left;
    unicode-bidi: bidi-override;
  }
}
</style>
