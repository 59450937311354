<template>
  <v-chip-group mandatory active-class="success" v-model="selectedLanguage">
    <v-chip
      v-for="(item, index) in languages"
      :key="index"
      :value="getKey(item)"
      filter
      label
    >
      {{ getValue(item) }}
    </v-chip>
  </v-chip-group>
</template>

<script>
import { selectedLanguage, languages } from '@/config/config';
const initData = {
  selectedLanguage,
  languages,
};
export default {
  data: () => ({
    ...initData,
  }),
  methods: {
    getKey: (lang) => Object.keys(lang)[0],
    getValue: (lang) => Object.values(lang)[0],
    reset() {
      Object.assign(this.$data, initData);
    },
  },
  watch: {
    selectedLanguage(newValue, oldValue) {
      this.$emit('change', newValue);
    },
  },
  created() {
    this.$emit('change', this.selectedLanguage);
  },
};
</script>

<style lang="scss"></style>
